import {db} from '../utils/firebase';
import {Products_Coll, Categories_Coll, } from '../utils/constant';

export const get_products = () =>{
    return db.collection(Products_Coll).get().then(response => {return response})
}
export const get_products_cat = (category_id,searchKeyword) =>{
    //return db.collection(Products_Coll).where('catId', '==', category_id).get().then(response => {return response})
    let query = db.collection(Products_Coll).where('catId', '==', category_id);
  
    // if (searchKeyword) {
    //     query = db.collection(Products_Coll).where('catId', '==', category_id)
    //     .orderBy('title')
    //     .startAt(searchKeyword)
    //     .endAt(searchKeyword + '\uf8ff');
    // }
  
    return query.get().then((response) => {
      //console.log("responsive",response)
      return response;
    });
}
export const get_product_detail = (product_id) =>{
    return db.collection(Products_Coll).doc(product_id).get().then(response => {return response})
}

export const get_categories = () =>{
    return db.collection(Categories_Coll).get().then(response => {return response})
}

export const get_subproducts = (product_id) =>{
    return db.collection(Products_Coll).doc(product_id).collection('sub_products').get().then(response => {return response})
}